

@include bp-sm {
    #root {
        .element-page {    
            padding: 48px 0px !important;
            &.main {
                padding-left: 20px;
                padding-right: 20px;

                .content {
                    .card {
                        margin-bottom: 1rem !important;
                    }
                }
            }
            
        &.register-account {
            height: 100%;
            .form-account {
                padding-left: 20px;

                button {
                    width: 100%;
                }
            }
        }
        }

        nav {
            .container-fluid {
                width: 100%;
            }
        }


    }

    
    .modal {
        .modal-dialog {
            .modal-content {
                .modal-body {
                    .form-group {
                        &.with-icon {
                            margin-bottom: 5px;
                        }
                    }
                }
            }
        }
    }

    /** Helpers */    
    .pro-sidebar {
        border-right: unset;
        .head-div { 
            .icon-suffix { 
                padding: 0 15px;
            }
        }
    }

    /** Header */
    nav.navbar {
        padding: 15px; 
        img {
            height: 25px;
        }
    }
}
.btn {
    border-radius: 25px; margin: 20px 5px; color: $primary;
    padding: 10px 40px; border-color: $primary; background-color: $white;
    text-transform: uppercase; font-family: 'Archia', 'Roboto', sans-serif;
	@include transition(all .3s ease);
    width: fit-content;
    
    &:hover, &:focus, &:active {
        border-color: $primary;
    }
    &.btn-primary {
        background-color: $primary; color: $white;
        
        &:hover, &:focus, &:active {
            color: $primary; background-color: $white;
        }
    }

    &.btn-sm {
        margin: 5px; padding: 10px 30px; 
    }

    &.btn-cancel {
        border-color: $cancel;
        background-color: $cancel;
        color: $white;
        &:hover, &:focus, &:active {
            color: $primary; background-color: $white;
            border-color: $primary;
        }
    }
    &.btn-upgrade {
        border-color: $upgrade;
        background-color: $upgrade;
        color: $white;
        &:hover, &:focus, &:active {
            color: $primary; background-color: $white;
            border-color: $primary;
        }
    }
}

a { 
    cursor: pointer !important;
    link {
        color: $primary;
        text-decoration: underline;
    }
}
#root {
    position: relative;
    .element-page {
        &.splash {
            img { 
                height: 76px; width: 100%; object-fit: contain;
            }
            .beat {
                animation: beat 1.5s infinite alternate;
	            transform-origin: center;
            }
        }

        &.register-account {
            height: 100%;
            .step-menu {
                height: inherit; display: flex; align-items: center;
                counter-reset: step-counter;
                // border-right: 1px solid $primary;
                overflow-y: hidden;

                .step-menu__list li {
                    padding: 20px 15px; padding-right: 60px;
                    position: relative;

                    &:before, &:after {
                        position: absolute; top: 0; right: 10px; z-index: 100;
                    }
                    &:after { 
                        bottom: 0; transform: translateY(-50%);
                        content: ''; height: 100vh; width: 1px; background-color: $primary;
                    }
                    &:before {
                        right: -10px; z-index: 105;
                        counter-increment: step-counter;
                        content: counter(step-counter);
                        @include circle; border-color: $white;
                    }
                    &:nth-child(n+1) {
                        &:before {
                            margin: 18px 0;
                        }
                    }
                    &.active {
                        &:before {
                            @include circle-active;
                        }
                    }

                    &:not(.active) {
                        h2, p { opacity: 0.6; }
                    }
                }
            }

            .form-account {
                padding-left: 60px;
            }
            .payment-monthly-sub {
                .show {
                    
                }
            }
        }

        &.register-device {
            height: 60%;
        }
        
        &.step-header {
            min-height: 200px; height: 200px;
            counter-reset: step-header-ctr;

            .step-header__list {
                height: 100%; 
                .col {
                    position: relative;
                    &:before, &:after {
                        position: absolute; top: -45px; z-index: 100;
                    }
                    &:after { 
                        content: ''; bottom: 0; top: -24.5px; left: 0;
                        height: 1px; width: 100%; background-color: $dgrey;
                    }
                    &:before {
                        z-index: 105; left: calc(50% - 20px);
                        counter-increment: step-header-ctr;
                        content: counter(step-header-ctr);
                        @include circle; border-color: $dgrey;
                    }

                    &.active {
                        &:before {
                            @include circle-active;
                        }
                    }
                }


            }
        }

        &.main {
            margin-top: 80px;
            height: auto; min-height: 100%;
            // padding-left: 50px;
            // padding-right: 50px;
            // width: 100%;
            .sidenav {
                height: 100%; display: flex; align-items: center;
                background-color: $primary; justify-content: center;
                padding: 0;

                ul {
                    width: 100%; padding: 0;
                    li {
                        padding: 15px 40px;  width: 100%;

                        h3 { margin-bottom: 0; }

                        &.active {
                            border-right: 5px solid $white;
                        }
                    }
                }
            }

            .content {
                // padding: 0 15px;
                #breakdown {
                    padding: 20px 0;

                    .each {
                        padding: 20px; margin: 10px auto;
                        background-color: $secondary; border-radius: 20px;

                        &.gradient {
                            background: rgb(103,73,160);
                            background: linear-gradient(175deg, rgba(103,73,160,1) 30%, rgba(94,74,222,1) 100%, rgba(255,255,255,0.9836309523809523) 100%);
                        }
                    }
    
                    .current-currency {
                        h5 { margin-bottom: 0; }
                        label { color: $primary; }
                    }

                    .current-currency-breakdown {
                        position: relative;
                        padding-top: 55px;

                        .status {
                            position: absolute; top: 0; right: 0;
                            background-color: $error; padding: 15px;
                            border-top-right-radius: 20px;
                            border-bottom-left-radius: 20px;
                        }

                        small {
                            @include body-xs; color: $dgrey;
                            svg { height: 14px; width: 14px; fill: $icons; }
                        }
                        h6 { padding-left: 17px; }
                    }
                }
                #chart {
                    background-color: $secondary; border-radius: 20px; padding: 20px;
                    color: $white !important;
                }


                .card {
                    border-radius: 15px; background-color: $bggray;
                    color: $white; position: relative; height: 100%;

                    .card-title.h5 {
                        padding-right: 50px;
                    }
                    .card-text.status {
                        position: absolute; top: 0; right: 0;
                        padding: 8px 20px; border-top-right-radius: 15px; border-bottom-left-radius: 15px;

                        &.active { background-color: $success; }
                        &.cancel { background-color: $error; }
                        &.expired, &.free { background-color: $warning;}
                    }
                    .card-text.crypto-logo {
                        img { 
                            height: 30px; border-radius: 15px; background-color: $white; margin-right: 5px;
                        }
                    }

                    &:hover {
                        cursor: pointer; background-color: #373d42;
                        @include transition(all .3s ease);
                    }

                    &.add-new {
                        background-color: $primary; 

                        .card-body {
                            position: absolute; top: 50%; left: 50%;                        
                            transform: translate(-50%, -50%);

                            svg { width: 30px; height: 30px; }
                        }
                    }
                }
            }

            .content-link {
                .each {
                    border: 2px solid $white; border-radius: 40px; width: max-content;
                    padding: 15px; margin: 5px auto; cursor: pointer; 
                    background-color: $black;
                    @include transition(all .3s ease);

                    img { height: 40px; width: 100%; object-fit: contain; }

                    &:hover {
                        background-color: $primary;
                    }
                }
            }
        }

        &.update-device {
            margin-top: 80px;
            
            .form-group {
                width: 100%; border-radius: 20px; cursor: pointer; padding: 10px;
                img { width: inherit; height: auto; }
                label { margin: 10px auto; width: inherit; text-align: center;}
                @include transition(all .3s ease);

                &:hover, &.selected {
                    background-color: $primary;
                }


                &.with-icon {
                    position: relative; padding-left: 70px; padding-top: 0; padding-bottom: 0;
                    label { text-align: left; cursor: pointer;}
                    img, .avatar {
                    position: absolute; left: 20px; top: 50%; width: 40px; height: auto;
                        background-color: $white; border-radius: 50px;
                        transform:  translateY(-50%);

                        &.light { background-color: $white; height: 40px;}
                        &.dark { background-color: $black; height: 40px;}
                        &.cryptox { background-color: $primary; height: 40px; border: 1px solid white}
                    }

                    &.avatar { padding: 10px 10px 10px 60px; 
                        .avatar { left: 15px; }
                    }
                }
            }

            .css-1nmdiq5-menu {
                color: $black
            }
            .with-options {
                position: relative;

                .custom-dropdown-menu {
                    position: absolute;
                    top: 70px;
                    left: 20px;
                    background-color: white;
                    color: black;
                    padding: 20px;
                    z-index: 9999;
                    width: inherit;
                }
            }
        }

        &.footer {
            border-top: 1px solid $primary; width: 100%; 
            padding-right: 100px; padding-left: 100px;
        }
    }

    nav {
        .nav-link {
            color: $white; padding: 8px 15px; margin: auto 5px;
            @include transition(all .3s ease);
            border-bottom: 2px solid transparent;

            &.active {
                border-bottom: 2px solid $white;
            }
            &:hover {
                color: $primary;
                border-bottom: 2px solid $primary;
            }

            svg {
                font-size: 25px; margin: 0px 8px; margin-top: -2px;
            }
        }

        .navbar-toggler-icon {
            filter: invert(1)
        }
    }
}


.pricing {
    .each { 
        text-align: center;
        padding: 10px 30px;
        > label {
            cursor: pointer;
            border-radius: 30px;
            padding: 30px;
            text-align: center;
            height: 100%;
            width: 100%;
            border: 1px solid $white;

            &:hover, &.active {
                border-color: $primary;
            }
        }

        span { 
            width: 100%;
            text-align: left;
            display: block;
            margin-top: 10px;
        }
    }
}

@keyframes beat{
	to { transform: scale(1.1); }
}

.modal {
    .modal-dialog {
        .modal-content {
            border-radius: 15px; background-color: $bggray; color: $white;

            .modal-body {

                .form-group {
                    width: 100%; border-radius: 20px; cursor: pointer; padding: 10px;
                    img { width: inherit; height: auto; }
                    label { margin: 10px auto; width: inherit; text-align: center;}
                    @include transition(all .3s ease);

                    &:hover, &.selected {
                        background-color: $primary;
                    }


                    &.with-icon {
                        position: relative; padding-left: 70px; padding-top: 0; padding-bottom: 0;
                        label { text-align: left; cursor: pointer;}
                        img, .avatar {
                        position: absolute; left: 20px; top: 50%; width: 40px; height: auto;
                            background-color: $white; border-radius: 50px;
                            transform:  translateY(-50%);

                            &.light { background-color: $white; height: 40px;}
                            &.dark { background-color: $black; height: 40px;}
                            &.cryptox { background-color: $primary; height: 40px; border: 1px solid white}
                        }

                        &.avatar { padding: 10px 10px 10px 60px; 
                            .avatar { left: 15px; }
                        }
                    }
                }

                .css-1nmdiq5-menu {
                    color: $black
                }
            }

            .with-options {
                position: relative;

                .custom-dropdown-menu {
                    position: absolute;
                    top: 70px;
                    left: 20px;
                    background-color: white;
                    color: black;
                    padding: 20px;
                    z-index: 9999;
                    width: inherit;
                }
            }
        }
    }
}
.hidden {
    display: none;
}

.avatar {
    padding-right: 10px;
//     width: 40px; height: auto;
//     background-color: $white; border-radius: 50px;
//     padding: 7px 2px;

//         &.light { background-color: $white; height: 40px;}
//         &.dark { background-color: $black; height: 40px;}
//         &.cryptox { background-color: $primary; height: 40px; border: 1px solid white}
}
@font-face {
    font-family: 'Archia';
    src: url('./../../fonts/Archia-Regular.otf') format('woff');
}
@font-face {
    font-family: 'DIN Pro Regular';
    src: url('./../../fonts/dinpro/D-DIN-PRO-400-Regular.otf') format('woff');
}
@font-face {
    font-family: 'DIN Pro Medium';
    src: url('./../../fonts/dinpro/D-DIN-PRO-500-Medium.otf') format('woff');
}
@font-face {
    font-family: 'DIN Pro SemiBold';
    src: url('./../../fonts/dinpro/D-DIN-PRO-600-SemiBold.otf') format('woff');
}
@font-face {
    font-family: 'DIN Pro Bold';
    src: url('./../../fonts/dinpro/D-DIN-PRO-700-Bold.otf') format('woff');
}
@font-face {
    font-family: 'DIN Pro ExtraBold';
    src: url('./../../fonts/dinpro/D-DIN-PRO-800-ExtraBold.otf') format('woff');
}
@font-face {
    font-family: 'DIN Pro Heavy';
    src: url('./../../fonts/dinpro/D-DIN-PRO-900-Heavy.otf') format('woff');
}

h1 { font-family: 'Archia', 'Roboto', sans-serif; margin-bottom: 30px; }
h2, h3, h4, h5, h6, p, label { font-family: 'DIN Pro Regular','Archia', 'Roboto', sans-serif; }

@mixin body-xl     { font-size: 24px; }
@mixin body-lg     { font-size: 20px; }
@mixin body-reg    { font-size: 18px; }
@mixin body-med    { font-size: 16px; }
@mixin body-sm     { font-size: 14px; }
@mixin body-xs     { font-size: 12px; }    

.form-control:disabled {
    background-color: grey;
}

@mixin transition($args...) {
    -webkit-transition: $args;
    -moz-transition: $args;
    -ms-transition: $args;
    -o-transition: $args;
    transition: $args;
}

@mixin circle {
    border-radius: 25px;
    height: 40px; width: 40px; background-color: $black;
    display: flex; align-items: center; justify-content: center;
    border: 1px solid transparent;
}

@mixin circle-active {
    background-color: $primary; border-color: $primary;
}

@mixin bp-xl {
    @media (min-width: 1200px) {
        @content;
    }
}

@mixin bp-lg {
    @media (max-width: 1199px) {
        @content;
    }
}

@mixin bp-md {
    @media (max-width: 991px) {
        @content;
    }
}

@mixin bp-sm {
    @media (max-width: 767px) {
        @content;
    }
}

@mixin bp-xs {
    @media (max-width: 576px) {
        @content;
    }
}
html, body {
    width: 100%; height: 100%;
    padding: 0; margin: 0; overflow-x: hidden;
    
    background-image: url('../../images/banner.png');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
    font-family: 'Archia','Roboto', sans-serif; color: white;
}

#root {
    // height: auto; min-height: 100%;
    height: inherit;
    .container-fluid {
        width: 90%; margin: 0 auto;
        // > .row { height: 100%; }
    }
}

ul { list-style: none; }
.text-right { text-align: right; }

.apexcharts-menu { 
    background-color: rgba($black, 0.6) !important;

    .apexcharts-menu-item {
        &:hover {
            color: $black !important;
        }
    }
}

.form-label {
    margin: 1rem 0; margin-top: 1.5rem;
}

.input-group {
    position: relative; 

    input {
        border-radius: 25px !important; z-index: 10;
    }
    svg {
        width: 20px; height: 20px; fill: $black; 
        position: absolute; top: calc(50% - 10px); right: 15px;
        cursor: pointer; z-index: 11;
    }
}
.pro-sidebar {
    font-family: 'DIN Pro Regular','Archia', 'Roboto', sans-serif;
    position: absolute !important;
    background-color: transparent !important;
    height: inherit; border-right: 1px solid #333;

    a.text-decoration-none {
        background-color: $black !important; padding: 30px;

        img {
            height: 20px; margin: 0 auto;
        }
    }


    .head-div { 
        padding: 24px 0 !important; background-color: $black;
        a { padding: 15px }

        .icon-suffix { 
            padding: 0 5px;
        }
    }
    .sidebar-content {
        ul {
            a {
                @include body-lg;
                li { margin: 0; padding: 10px 15px; }
                
                &.active {
                    li {
                        border-right: 5px solid $white;
                    }
                }
            }
            
        }
    }
}
.pro-sidebar {
    width: 200px !important; min-width: 200px !important;
}

.pro-sidebar.toggled {
    width: 80px !important; min-width: 80px !important;
}

.breadcrumb a { color: $primary; }

.required-validate {
    label { color: red !important; }
    input { border:2px solid red !important; }    
}

table.table {
    vertical-align: middle; border-radius: 5px;

    thead {
        font-weight: 700;
    }
}
.modal-dialog {
    color: $black;
}
.element-page.main {
    // .row {
    //     display: -webkit-box;
    //     display: -webkit-flex;
    //     display: -ms-flexbox;
    //     display:         flex;
    //     flex-wrap: wrap;
    // }
      .row > [class*='col-'] {
        // display: flex;
        // flex-direction: column;
     }
}

.modal-header {
    button {
        color: #fff;
    }
}
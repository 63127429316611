.form-control, .CardField {
    border-radius: 25px; border: 1px solid $primary;
    padding: 10px 15px;
}
.ElementsApp, .ElementsApp .InputElement {
    padding: 50px !important;
}
.form-payment {
    margin-bottom: 20px;
}
.cardContainer {
    background-color: $white;
    border-radius: 25px;
    padding: 10px 15px;
}
$primary: #5E4ADE;
$secondary: #313359;
$tertiary: #2B2B51;
$white: #FFFFFF;
$black: #000000;
$blacksecondary: #050505;
$lgrey: #E7E7E7;
$dgrey : #8680AD;
$bggray : #282F35;

$success: #66E87C;
$error: #FE0000;
$warning: #FFB900;

$gradient1 : #6749A0;
$gradient2 : #5E4ADE;

$icons: #A589E4;

$upgrade: #66E77B;
$cancel: #FFB900;